<template>
  <div class="container-top">
    <div class="pc container">
      <div class="position-relative">
        <img class="img-event" src="https://launchpack-storage.s3.ap-northeast-2.amazonaws.com/media/non-path/event_page.png" alt="쿠폰 이벤트">
        <div class="link-1" @click="click1"></div>
        <div class="link-2" @click="click2"></div>
      </div>
    </div>
    <div class="mobile">
      <div class="position-relative">
        <img class="img-event" src="https://launchpack-storage.s3.ap-northeast-2.amazonaws.com/media/non-path/mo_event_page.png" alt="쿠폰 이벤트">
        <div class="link-1" @click="click1"></div>
        <div class="link-2" @click="click2"></div>
      </div>
    </div>
    <share ref="share" :sharedData="sharedData"></share>
  </div>
</template>

<script>
  import Share from "../component/Share";
  export default {
    name: "Event",
    components: {Share},
    created() {
      if(this.isLogin) {
        this.sharedData = {
          name: '플랫폼파이',
          desc: '개발 지식 없이도 경제적인 투자 비용으로, 플랫폼 기획 / 디자인 / 웹 & 앱개발 모두 가능!',
          link: `https://platformfy.com/home?recm=${this.user.recommend_code}`,
          img: '`https://launchpack-storage.s3.ap-northeast-2.amazonaws.com/media/non-path/`kakao_og_image.png'
        };
      }
    },
    data() {
      return {
        sharedData: {
          name: '',
          desc: '',
          link: '',
          img: ''
        },
      }
    },
    methods: {
      clickShare() {
        this.$refs.share.open();
      },
      click1() {
        if(this.isLogin) {
          this.clickShare();
        } else {
          this.$store.commit('setPrevPath', '/event');
          this.routerPush('/signin');
        }
      },
      click2() {
        if(this.isLogin) {
          this.routerPush('/inquiry_reg?type_id=22');
        } else {
          this.$store.commit('setPrevPath', '/inquiry_reg?type_id=22');
          this.routerPush('/signin');
        }
      }
    }
  }
</script>

<style lang="stylus" scoped>
  .pc
    .link-1
      cursor pointer
      position absolute
      top 1356px
      left calc(50% - 283px)
      width 566px
      height 97px
      //background-color rgba(255,255,255,0.4)

    .link-2
      cursor pointer
      position absolute
      top 2942px
      left calc(50% - 283px)
      width 566px
      height 97px
      //background-color rgba(255,255,255,0.4)

  .mobile
    .link-1
      cursor pointer
      position absolute
      top 37.5%
      left 15%
      width 69%
      height 3%
      //background-color rgba(255,255,255,0.4)

    .link-2
      cursor pointer
      position absolute
      top 83.3%
      left 15%
      width 69%
      height 3%
      //background-color rgba(255,255,255,0.4)
</style>
